// CardSection.js
'use client';

import React, { Suspense } from 'react';
import dynamic from 'next/dynamic';
import ReusableCard from '@/client/card/BaseCard';
import styles from './CoreServices.module.css';

const WebDevelopmentImg = dynamic(() => import('@/img/services/Deflector'), { ssr: false });
const SeoOptimizationImg = dynamic(() => import('@/img/services/Lateral'), { ssr: false });
const PerformanceImg = dynamic(() => import('@/img/services/Lunette'), { ssr: false });
const SecurityImg = dynamic(() => import('@/img/services/Parebrise'), { ssr: false });

const ImagePlaceholder = () => (
  <div className={styles.imagePlaceholder}>
    <div>Loading...</div>
  </div>
);

const CardSection = () => {
  return (
    <div className={styles.cardContainer}>
      <Suspense fallback={<ImagePlaceholder />}>
        <ReusableCard BackgroundComponent={WebDevelopmentImg} title='Développement Web Full-Stack' link='/services/web-development'>
          Deagle.dev vous accompagne dans la création de sites web performants et sécurisés, répondant à vos besoins spécifiques grâce à des solutions
          sur-mesure.
        </ReusableCard>
        <ReusableCard BackgroundComponent={SeoOptimizationImg} title='Optimisation SEO' link='/services/seo-optimization'>
          Améliorez votre visibilité en ligne avec nos services d&apos;optimisation SEO, pour un meilleur classement dans les moteurs de recherche et une
          audience ciblée.
        </ReusableCard>
        <ReusableCard BackgroundComponent={PerformanceImg} title='Optimisation de la Performance' link='/services/performance-optimization'>
          Accélérez le temps de chargement de votre site et améliorez l&apos;expérience utilisateur grâce à notre expertise en optimisation de la performance
          web.
        </ReusableCard>
        <ReusableCard BackgroundComponent={SecurityImg} title='Sécurité Informatique' link='/services/security'>
          Protégez vos données sensibles et sécurisez votre infrastructure avec nos solutions de sécurité informatique avancées et adaptées à vos besoins.
        </ReusableCard>
      </Suspense>
    </div>
  );
};

export default CardSection;
